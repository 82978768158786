import {Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core'

@Directive({
  selector: '[bgoOpenNewTab]',
})
export class OpenNewTabDirective implements OnInit {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  // support for angular routerLink
  @Input() routerLink?: string
  @HostListener('mousedown') onMouseDown() {
    if (this.routerLink && window) {
      window.open(this.routerLink)
    }
  }

  ngOnInit() {
    // add target=_blank for support with standard href
    this.renderer.setAttribute(this.elementRef.nativeElement, 'target', '_blank')
    this.renderer.setAttribute(this.elementRef.nativeElement, 'rel', 'noopener noreferrer')
  }
}
